<template>
  <vue-modal @close="$emit('close')" ref="modal" size="sm">
    <template #title>Categoría</template>
    <template #body>
      <!-- <FormText
       v-if="item && item.id"
        label="Nombre"
        icon="fa-solid fa-tag"
        v-model="form.nombre"
        :disabled="true"
      /> -->
      <FormText
        label="Nombre"
        icon="fa-solid fa-tag"
        v-model="form.nombre"
      />
      <FormText
        label="Direccion"
        icon="fa-solid fa-home"
        v-model="form.domicilio"
      />
      <FormText
        label="Telefono"
        icon="fa-solid fa-phone"
        v-model="form.telefono"
      />
      <!-- <FormAutoComplete 
        v-else
        label="Nombre de Juez"
        icon="fa-solid fa-tag"
        v-model="form.nombre"
        :data="listaPersonas"

      /> -->
      <FormSelect
        v-model="form.jueces_tipo"
        label="Tipo de Juez"
        :options="listaTipoJueces"
        icon="fa-solid fa-tag"
      />
      <div class="row justify-content-around">
        <div v-for="tipo in form.jueces_tipo" :key="tipo.value">
          <FormSelect
            style="min-width: 350px"
            v-model="form.categorias[tipo.value]"
            :label="`Categorías de ${tipo.name}`"
            :options="listaCategorias"
            icon="fa-solid fa-tag"
          />
        </div>
      </div>
      <div class="button_container">
          <div v-for="(tipo) in form.jueces_tipo" :key="tipo.value" class="d-flex flex-column gap-2 justify-content-arround">
          <h5 class="text-center">Tipo Juez</h5>
          <button  class="btn btn-secondary-shade">
          {{tipo.name}}
          <i class="fa-solid fa-times close" @click="handleRemoveJuez(tipo)"></i>
          </button>
          <h5 class="text-center">Categirias</h5>
          <button  class="btn btn-secondary-shade" v-for="(cat, index) in form.categorias[tipo.value]" :key="index" >
          {{cat.name}}
          <i class="fa-solid fa-times close" @click="handleRemoveGaleria(cat,tipo.value)"></i>
          </button>
          </div>
      </div>
    </template>
    <template #footer>
      <button class="btn btn-primary" @click="saveData">Guardar</button>
    </template>
  </vue-modal>
</template>
<script>
import FormText from "@/components/Form/FormText.vue";
import FormSelect from "../../../components/Form/FormSelect.vue";
// import FormAutoComplete from "../../../components/Form/FormAutoComplete.vue";

export default {
  components: {
    FormText,
    FormSelect,
    // FormAutoComplete
},
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: {
      categorias: {
        1: [],
        2: [],
      },
      jueces_tipo:[],
    },
  }),
  //  categorias: this.item.categorias.map(cat => ({value: cat.id, name: cat.categoria})), id_persona: this.item.id};

  async mounted() {
    if (this.item) {
      this.form = {...this.item}
    }
    this.$store.dispatch('getListaJuecesTipo')
    this.$store.dispatch('getListaCategoriasTorneos')
    this.$store.dispatch('getListaPersonas');
  },
  computed:{
    listaTipoJueces(){
       if (!this.$store.getters.listaJuecesTipo) return []
      return this.$store.getters.listaJuecesTipo.map(tipo => ({value: tipo.id, name: tipo.tipo}))
    },
    listaCategorias(){
      if (!this.$store.getters.listaCategoriasTorneos) return []
      return this.$store.getters.listaCategoriasTorneos.map(cat => ({value: cat.id, name: cat.categoria}))
    },
    listaPersonas(){
      if (!this.$store.getters.listaPersonas) return []
      return this.$store.getters.listaPersonas.map(p => `${p.id} - ${p.nombre}`)
    }
  },
  methods: {
    close() {
      this.$refs.modal.close();
    },
    handleRemoveGaleria(cat,index){
      this.form.categorias[index] = this.form.categorias[index].filter(c => c.value != cat.value)
    },
    handleRemoveJuez(tipo){
      this.form.categorias[tipo.value] = []
      this.form.jueces_tipo = this.form.jueces_tipo.filter(t => t.value != tipo.value)
    },
    saveData() {
      if (!this.form.jueces_tipo.length) {return this.$toast.error("Seleccione un Tipo de Juez");}
      if (!this.form.categorias[1].length && !this.form.categorias[2].length) {return this.$toast.error("Seleccione una Categoria");}
      
      const id = this.form.id_persona

      const form = {
        id_persona: this.form?.id_persona || null,
        domicilio: this.form?.domicilio || null,
        telefono: this.form?.telefono || null,
        nombre: this.form.nombre,
        categorias: Object.keys(this.form.categorias).map(x=>this.form.categorias[x].map(cat => cat.value)),
        jueces_tipo: this.form.jueces_tipo.map(tipo => tipo.value),
      }
      if (id) {
        this.$store
          .dispatch("updateJuez", {
            id,
            form,
          })
          .then(() => {
            this.close();
          });
      } else {
        this.$store.dispatch("createJuez", form).then(() => {
          this.close();
        });
      }
      this.$emit('saved')

    },
  },
};
</script>
<style lang="scss" scoped>
  .button_container{
    display: flex;
    flex-wrap: wrap;
    justify-content:space-around;
    padding-top: 28px;
    gap: 5px;
  }
  .close{
    margin-left: 5px;
     &:hover{
      cursor: pointer;
      color: black;
    }
  }
  

</style>
