<template>
  <div class="row">
    <div class="col-12">
      <Widget class="h-auto">
        <template #title>Jueces</template>

        <button v-if="canPost" class="btn btn-block btn-medium" @click="createItem">
          Nuevo Juez
        </button>
        <vue-table
          v-if="vTable.headers"
          :values="items"
          :headers="vTable.headers"
          :actions="actions"
          :options="vTable.options"
          ref="vtable"
          :empty_message="canGet ? `No hay jueces para mostrar` : `No tienes permisos para ver jueces`"
          @changed="getItems"
          @onShowItem="onShowItem"
          @onDeleteItem="deleteItem"
        />
      </Widget>
    </div>

    <JuecesModal
      v-if="showModal"
      @close="closeModal"
      :item="selItem"
      @saved="onSaveItem"
    />
  </div>
</template>

<script>
import Widget from "@/components/Widget.vue";
import JuecesModal from "./JuecesModal.vue";
import {can} from '@/acl/methods'

export default {
  components: {
    Widget,
    JuecesModal,
  },
  data: () => ({
    vTable: {
      headers: [
        // {
        //   title: "id",
        //   mask: "ID_PERSONA",
        //   sortable: true,
        // },
        {
          title: "nombre",
          mask: "Nombre",
          sortable: true,
        },
        {
          title: "domicilio",
          mask: "Domicilio",
          sortable: true,
        },
        {
          title: "telefono",
          mask: "Teléfono",
          sortable: true,
        },
      ],
      actions: [
        {
          buttonClass: "btn-warning",
          callback: "onShowItem",
          tooltip: "Editar Categoría",
          icon: "fa-solid fa-pencil",
        },
        {
          buttonClass: "btn-danger",
          callback: "onDeleteItem",
          tooltip: "Borrar Post",
          icon: "fa-solid fa-trash",
        },
      ],
      options: {
        /// Go to OPTIONS SECTION for explanation
      },
    },
    showModal: false,
    selItem: null,
  }),
  mounted() {
    this.initializeTable();
  },
  computed: {
    items() {
      return this.$store.getters.jueces;
    },
    canGet(){
      return can(this.$store.getters.user, 'categorias', ['get'])
    },
    canPost(){
      return can(this.$store.getters.user, 'categorias', ['post'])
    },
    canPut(){
      return can(this.$store.getters.user, 'categorias', ['put'])
    },
    canDel(){
      return can(this.$store.getters.user, 'categorias', ['delete'])
    },
    actions(){
      const actions = this.vTable.actions
      // if (!this.canPut) {
      //   actions.splice(actions.findIndex(a => a.callback === 'onShowItem'), 1)
      // }
      // if (!this.canDel) {
      //   actions.splice(actions.findIndex(a => a.callback === 'onDeleteItem'), 1)
      // }
      return actions
    }
  },
  methods: {
    initializeTable() {
      this.$refs.vtable.init();
    },
    closeModal() {
      this.selItem = null;
      this.showModal = false;
    },
    getItems(params) {
      this.$store.dispatch("getJueces", params);
    },
    onShowItem(item) {
      this.showModal = true;
      this.selItem = item;
    },
    createItem() {
      this.showModal = true;
    },
    onSaveItem() {
      this.initializeTable(); // Reset table
    },
    deleteItem(item) {
      this.$alert
        .fireConfirm("Borrar Juez", "Está seguro que desea continuar?")
        .then(() => {
          this.$store.dispatch("deleteJuez", item.id_persona).then(() => {
           this.onSaveItem();
        });
        });
    },
  },
};
</script>
